<template>
  <div>
    <div class="row mt-2">
      <div class="col-12 col-lg-6">
        <span class="text-uppercase text-muted font-weight-bold">{{
          getTrans("messages.wl_title")
        }}</span>
        <base-input
          :placeholder="getTrans('messages.enter_name_wl')"
          v-model="title"
          :error="getError('title')"
        ></base-input>
        <br />
        <span class="text-uppercase text-muted font-weight-bold">{{
          getTrans("messages.wl_domain")
        }}</span>
        <base-input
          :placeholder="getTrans('messages.enter_domain_wl')"
          v-model="domain"
          :error="getError('domain')"
        ></base-input>
      </div>
      <div class="col-12 col-lg-6 border border-info">
        <span>{{ getTrans("messages.whitelabel_info_text") }}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 border border-info">
        <span class="text-uppercase text-muted font-weight-bold"
          >{{ getTrans("messages.room_url") }}:</span
        >
        <br />
        <span>{{ url }}</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-lg-1 mr-2">
        <base-button type="danger">{{
          getTrans("messages.delete")
        }}</base-button>
      </div>
      <div class="col-12 col-lg-1 ml-2">
        <base-button type="primary">{{
          getTrans("messages.save")
        }}</base-button>
      </div>
    </div>
  </div>
</template>
<script>
import Errors from "@/mixins/Errors";
import { mapGetters } from "vuex";

export default {
  mixins: [Errors],
  props: {
    user: {
      type: Object,
    },
    getError: {
      type: Function,
    },
    camsite: {
      type: Object,
    },
  },
  data() {
    return {
      title: "",
      domain: "",
      errors: {},
      active: false,
      currentSettings: null,
    };
  },
  watch: {
    title() {
      this.update();
    },
    domain() {
      this.update();
    },
  },
  computed: {
    ...mapGetters("Whitelabel", {
      whitelabels: "getData",
      loading: "loading",
    }),
    whitelabel() {
      if (this.whitelabels && this.camsite) {
        return this.whitelabels.filter(
          (w) => w.platform_id === this.camsite.id
        )[0];
      } else {
        return null;
      }
    },
    url() {
      return `http://${this.domain}/ModelName?utm_source={source}&utm_medium=popups&utm_campaign=divatraffic`;
    },
    canDelete() {
      if (this.whitelabel) {
        return true;
      }
      return false;
    },
  },
  methods: {
    isValidDomain(val) {
      const regex = /^((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/im;
      return regex.test(val);
    },

    update() {
      if (this.isValidDomain(this.domain)) {
        this.errors.domain = [];
        this.$emit("updated", {
          title: this.title,
          domain: this.domain,
          url: this.url,
          active: this.active,
        });
      } else {
        this.errors.domain = ["Not a valid domain"];
      }
    },
    save() {
      let setting = {
        id: this.camsite.id,
        title: this.title,
        domain: this.domain,
        url: this.url,
        active: this.active,
      };
      this.$store.dispatch("Whitelabel/save", setting);
    },
    delete() {
      if (this.canDelete) {
        this.$store
          .dispatch("Whitelabel/delete", this.camsite.id)
          .then(() => {
            this.$notify.success(this.getTrans("messages.whitelabel_deleted"));
          })
          .catch(() => {
            this.$notify.error(this.getTrans("messages.user_deleted_failed"));
          });
      }
    },
  },
  mounted() {
    this.currentSettings = this.whitelabels.filter(
      (w) => w.platform_id === this.camsite.id
    )[0];
    if (this.currentSettings) {
      this.title = this.currentSettings.title;
      this.domain = this.currentSettings.domain;
      this.active = this.currentSettings.active;
    }
  },
};
</script>
