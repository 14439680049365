<template>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <base-input :label="getTrans('messages.select_program')">
          <el-select v-model="selectedProgramID">
            <el-option
              v-for="program in camsite.programs.data"
              :key="program.value"
              :label="program.title"
              :value="program.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div v-if="selectedProgram">
      <div
        class="row"
        v-for="(param, index) in selectedProgram.aff_params"
        :key="index"
      >
        <div class="col-12 col-lg-3 mt-2">
          <base-input
            v-model="input[param]"
            :label="param"
            clearable
            @change="paramUpdated(param)"
						:error="getError(param)"
          ></base-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {  ElSelect, ElOption } from "element-plus";
import Errors from "@/mixins/Errors";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption
  },
	mixins: [Errors],
  props: {
    camsite: Object,
    user: Object,
    getError: Function,
  },
  data() {
    return {
      selectedProgramID: "",
      input: {},
      errors: {},
    };
  },
  watch: {
    selectedProgram() {
      this.programChanged();
    },
    input: {
      deep:true,
      handler() {
        this.paramUpdated();
      }
    }
  },
  computed: {
    selectedProgram() {
      if (this.selectedProgramID) {
        let c = this.camsite.programs.data.find(p => {
          return p.id == this.selectedProgramID;
        });
        return c;
      }
      return null;
    }
  },
  methods: {
    paramUpdated() {
      let val = this.input;
      if (this.selectedProgram) {
        let newVal = {};
        if (val) newVal = val;

        let programUrl = this.selectedProgram.url;
        // getting parameter name from url (name in database might be different)
        let programParams = this.getParamsFromCurlyUrl(programUrl, this.selectedProgram.aff_params) 

        //going through parameters and matching them with url params pasted by user
        for (let paramName of this.selectedProgram.aff_params) {
          try {
            let urlCheck = new URL(newVal[paramName]);

            for (let pName of this.selectedProgram.aff_params) {
              let programUrlParam = programParams[pName];
              if (programUrlParam && urlCheck.searchParams.get(programUrlParam)) {
                  newVal[pName] = urlCheck.searchParams.get(programUrlParam);
              }
            }
          } catch (e) {
              //input not url or doesnt contain all params
          }
          let newValidation = this.errors;
          newValidation[paramName] = this.validAffiliateProgramSetting(newVal[paramName]);
          this.errors = newValidation;
        }
        this.input = newVal;
        this.$emit('updated', {id: this.camsite.id, program_id: this.selectedProgram.id, params: newVal})
      }
    },
    getParamsFromCurlyUrl(url, params) {
      //extract params from https://chaturbate.com/in/?track=default&tour=yiMH&campaign={campaignID}&room={identifier}
      //URL fails to parse because of {}
      let programParams = {};
      // eslint-disable-next-line
      for(let param of params) {
          let parts = url;
          if (parts.indexOf('?') >= 0) {
              parts = parts.split('?')[1];
          } else if (parts.lastIndexOf('/') >= 0) {
              parts = parts.split('/')[1];
          }
          parts.split('&').forEach((item) => {
              let pair = item.split('=');
              if (pair[1].includes('{')) {
                  let first = pair[0];
                  let second = pair[1];
                  second = second.substring(1, second.length-1);
                  programParams[second] = first;
              }
          });
      }
      return programParams;
    },
    programChanged() {
      if (this.selectedProgram) {
        let settings = this.user?.data?.platforms?.data?.find(p => p.platform_id == this.camsite.id && p.platform_program_id == this.selectedProgramID);
        if (settings) {
          this.selectedProgram.aff_params.forEach((p) => {
            this.input[p] = settings[p];
          });
        }
      } else {
        this.input = {};
      }
    },
		validAffiliateProgramSetting(param) {
      if (!param || param.length == 0) {
        return true;
      }if (/[=?/&]+/.test(param)) {
        return false;
      }
      try {
        new URL(param);
        return false;
      } catch (e) {
        return true;
      }
    },
  },
  mounted() {
    this.selectedProgramID = this.user?.data?.platforms?.data.find(p => p.platform_id == this.camsite.id)?.platform_program_id;
  }
};
</script>
