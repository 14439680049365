<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>
          <span class="text-uppercase text-muted font-weight-bold"
            >{{getTrans('messages.max_viewers')}}</span
          >
        </h5>
      </div>
    </div>
    <div class="col-12">
      <div class="col-lg-8">
        <el-slider v-model="maxViewers" :min="10" :max="platformMax" show-input>
        </el-slider>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="text-uppercase text-muted font-weight-bold"
          >{{getTrans('messages.blocked_countries')}}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <base-input>
          <el-select
            v-model="selectedCountry"
            filterable
            multiple
          >
            <el-option
              v-for="country in getCountries"
              :key="country.id"
              :label="country.name"
              :value="country.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="text-uppercase text-muted font-weight-bold"
          >{{getTrans('messages.affiliate_settings')}}</span
        >
        <base-alert
          :icon="'fas fa-info-circle'"
          type="info"
          :dismissible="true"
          >
          <h3>{{getTrans('extra2.affiliate_info_title')}}</h3>
          <div>{{getTrans('extra2.affiliate_info_description')}}
          </div>
          <a
            href="https://support.divatraffic.com/knowledgebase/article/earn-higher-commission-as-an-affiliate"
            >{{getTrans('extra2.affiliate_info_link')}}</a
          >
        </base-alert>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <el-radio-group v-model="radio">
          <el-radio border :label="'earnTokens'"
            >{{getTrans('extra2.affiliate_earn_tokens')}}</el-radio
          >
          <el-radio border :label="'ownLink'"
            >{{getTrans('extra2.affiliate_your_own_links')}}</el-radio
          >
          <el-radio border :label="'whitelabel'"
            >{{getTrans('extra2.affiliate_your_own_whitelabel')}}</el-radio
          >
        </el-radio-group>
      </div>
    </div>
    <div class="row" v-if="radio == 'earnTokens'">
      <div class="col-12">
        <earn-tokens></earn-tokens>
      </div>
    </div>
    <div class="row" v-if="radio == 'ownLink'">
      <div class="col-12">
        <own-link :camsite="camsite" @updated="ownLinkUpdated" :user="user" :getError="getError"></own-link>
      </div>
    </div>
    <div class="row" v-if="radio == 'whitelabel'">
      <div class="col-12">
        <own-whitelabel @updated="ownWhitelabelUpdated" :user="user" :getError="getError" :camsite="camsite"></own-whitelabel>
      </div>
    </div>
    <div class="row mt-4" v-if="radio != 'whitelabel'">
      <div class="col-12 col-lg-1 mr-2">
        <base-button type="danger" @click="deleteSettings">{{getTrans('messages.delete')}}</base-button>
      </div>
      <div class="col-12 col-lg-1 ml-2">
        <base-button type="primary" @click="saveUser">{{getTrans('messages.save')}}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EarnTokens from "./EarnTokens";
import OwnLink from "./OwnLink";
import OwnWhitelabel from "./OwnWhitelabel";
import BaseAlert from '@/components/BaseAlert.vue'

import {
  ElAlert,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElSlider,
  ElSelect,
  ElOption,
} from "element-plus";
export default {
  components: {
    EarnTokens,
    OwnLink,
    OwnWhitelabel,
    [ElAlert.name]: ElAlert,
    [ElRadio.name]: ElRadio,
    [ElRadioButton.name]: ElRadioButton,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElSlider.name]: ElSlider,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    BaseAlert,
  },
  emits: ['save'],
  props: {
    user: Object,
    getError:Function, 
    camsite:Object
  },
  data() {
    return {
      radio: "earnTokens",
      maxViewers: 500,
      platformMax: 2000,
      currentBCountry: [],
      aff_key: "",
      program: "",
      inputs: {
        file: []
      },
      activeTab: "account",
      checkboxes: {
        unchecked: false,
        checked: true,
        uncheckedDisabled: false,
        checkedDisabled: true
      },
      dates: {
        simple: new Date(),
        range: "2019-04-19 to 2019-04-24"
      },
      selectedCountry: []
    };
  },
  methods: {
    saveUser() {
      let u = {
        platform_id: this.camsite.id,
        max_viewers: this.maxViewers,
        id: this.camsite.id,
        aff_key: ""
      };
      this.selectedCountry.length > 0
        ? (u.blocked_countries = this.selectedCountry)
        : null;

      if (this.radio == "ownLink") {
        Object.assign(u, this.program);
      }

      if (this.radio == "whitelabel") {
        //whitelabel endpoint
        /*
        id
        platform_id
        title
        domain
        room_url
        active 1*/
      }

      this.$emit('save', u);
    },
    deleteSettings() {
      this.$swal.fire({
        title: "Are you you want to delete your current camsite settings?",
        type: "warning",
        showCancelButton: true
      }).then(() => {
        this.$store.dispatch("User/deleteCamsiteSettings", {
          id: this.camsite.id
        }).then(()=>{
          this.$toast.success(this.getTrans('messages.camsite_settings') + ': ' + this.getTrans('messages.settings_saved'));
        }).catch(()=> {
          this.$toast.error(this.getTrans('messages.camsite_settings') + ': ' + this.getTrans('messages.save_failed'));
        });
      });
    },
    ownLinkUpdated(program) {
      this.program = program;
    }
  },
  computed: {
    ...mapGetters("Country", ["getCountries"]),
    ...mapGetters("User", {getUser: 'getData'}),
    platformSettings() {
      return this.user?.platforms?.data?.find(p => p.platform_id == this.camsite.id);
    }
  },
  mounted() {
    if (this.platformSettings?.max_viewers) {
      this.platformMax = this.platformSettings.max_viewers;
    } else if (this.camsite.max_viewers){
      this.platformMax = this.camsite?.max_viewers;
    }
    this.selectedCountry = this.platformSettings?.blocked_countries;
  },
};
</script>
