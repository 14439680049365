<template>
  <div>
    <div class="row">
      <div class="col-12">
        <span
          >{{getTrans('extra2.affiliate_earn_tokens_description')}}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ElButton, ElButtonGroup } from "element-plus";
export default {
  components: {
    [ElButton.name]: ElButton,
    [ElButtonGroup.name]: ElButtonGroup
  },
  methods: {
    update() {}
  }
};
</script>
