<template>
<div>
  <h5 class="h3">{{getTrans('messages.camsite_settings')}}</h5>
  <form>
    <div class="row">
      <div class="col-12">
        <el-collapse accordion>
          <el-collapse-item
            v-for="camsite in getCamsites"
            :key="camsite.id"
            :name="camsite.id"
          >
            <template #title>
              <img
                class="h-75"
                :src="platformLogo(camsite.id)"
                @load="imageLoaded"
              />
            </template>
            <camsite-collapse-item @save="save" :user="user" :getError="getError" :camsite="camsite"
            ></camsite-collapse-item>
          </el-collapse-item>
        </el-collapse>
        <content-loader v-if="imagesLoaded < getCamsites.length || getCountries.length < 1"></content-loader>
      </div>
    </div>
  </form>
</div>
</template>
<script>

import CamsiteCollapseItem from "./CamsiteSettingsComponents/CamsiteCollapseItem";
import { mapGetters } from "vuex";
import {
  ElCollapse,
  ElCollapseItem
} from "element-plus";
import PlatformMixin from "@/mixins/PlatformMixin";

export default {
  name: "camsiteSettings",
  mixins: [PlatformMixin],
  components: {
    CamsiteCollapseItem,
    [ElCollapse.name]: ElCollapse,
    [ElCollapseItem.name]: ElCollapseItem,
  },
  props: {
    user: Object,
    getError:Function,
  },
  emits:['save'],
  data() {
    return {
      imagesLoaded: 0,
    }
  },
  methods: {
    filesChange(files) {
      this.inputs.file = files;
    },
    changeTab() {},
    save(settings) {
      this.$emit('save', settings);
    },
    imageLoaded() {
      this.imagesLoaded++;
    }
  },
  computed: {
    ...mapGetters("Platform", ["getCamsites"]),
    ...mapGetters("Country", ["getCountries"])
  },
  mounted() {
    this.$store.dispatch("Platform/get");
    this.$store.dispatch("Country/get");
  }
};
</script>
